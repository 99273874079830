import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Grid} from '@material-ui/core';
import {isEmpty} from '../../util/helpers';
import PageSection from '../common/PageSection';
import InlineEditTextField from '../common/InlineEditTextField';
import FormControlRadioGroup from '../common/FormControlRadioGroup';
import SuccessMessageComponent from '../common/SuccessMessageComponent';
import AdvancedSectionExpansion from '../common/AdvancedSectionExpansion';
import TestConnectionResultTable from './TestConnectionResultTable';
import {getContainerUrl} from './AzureCloudConnectionForm';

// noinspection FunctionNamingConventionJS
function AzureCloudConnectionEditForm(props) {

    const [errors, setErrors] = useState(props.errors);
    const [localInstanceCredentials, setLocalInstanceCredentials] = useState({
        connectionString: ''
    });
    const [showSavedMessage, setShowSavedMessage] = useState(false);
    const [azureStorage, setAzureStorage] = useState({
        accountName: '',
        containerName: '',
        endpoint: 'blob.core.windows.net',
    });

    const getContainerUrlLocal = useCallback(function getContainerUrlLocal() {
        return getContainerUrl(azureStorage.accountName, azureStorage.endpoint, azureStorage.containerName);
    }, [azureStorage]);

    useEffect(() => {
        if (isEmpty(props.errors.containerName)) {
            let containerUrl = getContainerUrlLocal();
            if (containerUrl !== props.basePrefix) {
                const url = new URL(props.basePrefix);
                const hostParts = url.host.split('.');
                setAzureStorage({
                    accountName: hostParts[0],
                    containerName: url.pathname.replace(/^\/+/g, ''),
                    endpoint: hostParts.slice(1, hostParts.length).join('.')
                });
            }
        }
    }, [props.basePrefix, getContainerUrlLocal, props.errors.containerName]);

    useEffect(() => {
        setErrors(props.errors);
    }, [props.errors]);

    const updateInstanceCredentialsType = async function updateInstanceCredentialsType(event) {
        props.setInstanceCredentials(event);
        await props.handleSubmit(event, {useInstanceCredentials: event.target.value});
        setShowSavedMessage(true);
    };

    const updateStorageAccountInfo = async function (event, data) {
        setAzureStorage({...azureStorage, ...data});
        await props.updateBasePrefixBasedOnAccountInfo(event, {...azureStorage, ...data});
    };

    const handleInstanceCredentials = async function handleInstanceCredentials(event, data) {
        event.preventDefault();
        setLocalInstanceCredentials({...localInstanceCredentials, ...data});
        await props.handleSubmit(event, {...localInstanceCredentials, ...data});
        await props.handleSubmit(event, {useInstanceCredentials: false});
    };

    //noinspection MagicNumberJS, ConditionalExpressionJS
    return (<form>
        <Grid container justifyContent='center'>
            <Grid item md={8} xs={12}>
                <InlineEditTextField name='name' label='Connection Name' labelVariant='body1' value={props.name}
                                     save={(event, data) => props.handleSubmit(event, data)}
                                     helperText='Name used to identify this cloud connection.'
                                     placeholder='Enter unique name' required
                                     updateMessage='Saved' errorMessage={errors.name}/>
            </Grid>
            <Grid item md={8} xs={12}>
                <InlineEditTextField name='notes' label='Cloud Connection Notes' labelVariant='body1'
                                     value={props.notes}
                                     save={(event, data) => props.handleSubmit(event, data)}
                                     placeholder='Enter notes about the cloud connection (optional)'
                                     multiline showCount inputProps={{maxLength: 1000}}
                                     updateMessage='Saved' errorMessage={errors.notes}/>
            </Grid>
            <Grid item md={8} xs={12}>
                <InlineEditTextField label='Storage Account Name' labelVariant='body1'
                                     name='accountName'
                                     value={azureStorage.accountName}
                                     save={(event, data) => updateStorageAccountInfo(event, data)}
                                     updateMessage='Saved'
                                     autoComplete='off'
                                     required errorMessage={errors.accountName}
                />
            </Grid>
            <Grid item md={8} xs={12}>
                <InlineEditTextField label='Container Name' labelVariant='body1'
                                     name='containerName'
                                     value={azureStorage.containerName}
                                     save={(event, data) => updateStorageAccountInfo(event, data)}
                                     updateMessage='Saved'
                                     autoComplete='off'
                                     required errorMessage={errors.containerName}
                />
            </Grid>
            <Grid item md={8} xs={12}>
                <AdvancedSectionExpansion mt={2}>
                    <InlineEditTextField name='basePrefix' label='Container Resource URL' labelVariant='body1'
                                         value={props.basePrefix}
                                         autoComplete='off'
                                         save={(event, data) => props.handleSubmit(event, data)} required
                                         placeholder='Enter URL for Azure Container'
                                         helperText='Ex: https://storage-account-name.blob.core.windows.net/container-name'
                                         errorMessage={errors.basePrefix} updateMessage='Saved' copyField={true}
                    />
                </AdvancedSectionExpansion>
            </Grid>
            <Grid item md={8} xs={12}>
                <PageSection title='Cloud Connection Credentials' mt={4} pb={0} titleVariant='body1' autoComplete='off'
                             subtitle='Credentials used to access this cloud connection.' subtitleVariant='body2'
                             action={<Grid container justifyContent='center'>
                                 <SuccessMessageComponent show={showSavedMessage} setShow={setShowSavedMessage} mr={2}/>
                             </Grid>}
                />
                <FormControlRadioGroup name='useInstanceCredentials' value={props.useInstanceCredentials}
                                       onChange={updateInstanceCredentialsType}
                                       options={[
                                           ...(props.showInstanceCredentialsOption ?
                                               [{value: true, label: 'Use instance identity'}] :
                                               []), {
                                           value: false, label: 'Use connection string'
                                       }]}
                />
            </Grid>
            {!props.useInstanceCredentials &&
            <Grid item md={8} xs={12}>
                <div style={{width: '100%', paddingLeft: '28px', marginTop: '-16px'}}>
                    <InlineEditTextField name='connectionString' label='Connection String'
                                         required={!props.useInstanceCredentials && !props.connectionStringSet}
                                         emptyText={`${props.connectionStringSet ? '**********' : 'None'}`}
                                         autoComplete='off'
                                         helpLink={{
                                             href: 'http://www.sftpgateway.com/azure-connection-string.html',
                                             target: '_blank',
                                             text: 'Where do I find the connection string?'
                                         }}
                                         value={props.connectionString} updateMessage='Saved'
                                         save={(event, data) => handleInstanceCredentials(event, data)}
                                         placeholder={`${props.connectionStringSet ? '**********'
                                             : 'Enter connection string'}`}
                                         helperText='This is the default connection string used for this cloud connection.'
                                         errorMessage={errors.connectionString}/>
                </div>
            </Grid>
            }
            {!isEmpty(props.connectivity) &&
            <Grid item md={8}>
                <TestConnectionResultTable provider={props.cloudProvider} connectivity={props.connectivity}
                                           connectionId={props.id}/></Grid>
            }
        </Grid>
    </form>);
}

AzureCloudConnectionEditForm.propTypes = {
    name: PropTypes.string,
    notes: PropTypes.string,
    basePrefix: PropTypes.string,
    cloudProvider: PropTypes.string,
    connectionString: PropTypes.string,
    connectionStringSet: PropTypes.bool,
    region: PropTypes.string,
    useInstanceCredentials: PropTypes.bool,
    setInstanceCredentials: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleCancel: PropTypes.func,
    errors: PropTypes.object,
    connectivity: PropTypes.object,
    showInstanceCredentialsOption: PropTypes.bool,
};

AzureCloudConnectionEditForm.defaultProps = {
    accessSecretSet: false,
};

export default AzureCloudConnectionEditForm;
