import React, {useEffect, useState} from 'react';
import {Grid, InputAdornment} from "@material-ui/core";
import InlineEditTextField from "../common/InlineEditTextField";
import PropTypes from "prop-types";
import PageSection from "../common/PageSection";
import {isEmpty} from "../../util/helpers";
import TestConnectionResultTable from "./TestConnectionResultTable";
import {Publish} from "@material-ui/icons";
import InlineEditFile from "../common/InlineEditFile";

// noinspection FunctionNamingConventionJS
function GoogleCloudConnectionEditForm(props) {
  const [errors, setErrors] = useState(props.errors);
  const [localToken, setLocalToken] = useState({
    jwtToken: '',
    jsonKeyFilename: '',
  });

  useEffect(() => {
    setErrors(props.errors);
  }, [props.errors]);

  const handleKeyUpdate = (inputFile) => {
    let reader = new FileReader();
    reader.onload = function readOnLoad(e) {
      let contents = e.target.result;
      let encodedKey = Buffer.from(contents).toString('base64')
      setLocalToken({...localToken, jwtToken: encodedKey, jsonKeyFilename: inputFile.name});
    };
    if (!isEmpty(inputFile)) {
      reader.readAsBinaryString(inputFile);
    }
  };

  const handleInstanceCredentials = async (event) => {
    event.preventDefault();
    await props.handleSubmit(event, {...localToken, useInstanceCredentials: false});
  };

  return (<form>
    <Grid container justifyContent='center'>
      <Grid item md={8} xs={12}>
        <InlineEditTextField name='name' label='Connection Name' labelVariant='body1' value={props.name}
                             save={(event, data) => props.handleSubmit(event, data)}
                             helperText='Name used to identify this cloud connection.'
                             placeholder='Enter unique name' required
                             updateMessage='Saved' errorMessage={errors.name}/>
      </Grid>
      <Grid item md={8} xs={12}>
        <InlineEditTextField name='notes' label='Cloud Connection Notes' labelVariant='body1'
                             value={props.notes}
                             save={(event, data) => props.handleSubmit(event, data)}
                             placeholder='Enter notes about the cloud connection (optional)'
                             multiline showCount inputProps={{maxLength: 1000}}
                             updateMessage='Saved' errorMessage={errors.notes}/>
      </Grid>
      <Grid item md={8} xs={12}>
        <InlineEditTextField name='basePrefix' label='GCS Bucket URI' labelVariant='body1'
                             value={props.basePrefix}
                             autoComplete="off"
                             save={(event, data) => props.handleSubmit(event, data)} required
                             placeholder='Enter GsUtil URI for GCP Bucket'
                             helperText='Ex: gs://bucket-name'
                             errorMessage={errors.basePrefix} updateMessage='Saved' copyField={true}
        />
      </Grid>
      <Grid container item md={8}>

        <PageSection title='Cloud Connection Credentials' mt={4} pb={0} titleVariant='body1'
                     subtitle='Credentials used to access this cloud connection.' subtitleVariant='body2'/>

      </Grid>
      <Grid item md={8} xs={12}>
        <InlineEditFile name='jwtToken' label='Upload Service Account JSON Key File'
                        displayValue={props.jsonKeyFilename}
                        required
                        uncontrolled
                        emptyText={(props.jwtTokenSet) ? 'Key is Set' : "No Key Set"}
                        autoComplete="off"
                        helpLink={{
                          href: 'http://www.sftpgateway.com/google-service-account-key-file.html',
                          target: '_blank',
                          text: 'Where do I find the Service Account JSON Key File?'
                        }}
                        onChange={handleKeyUpdate}
                        type='file' updateMessage='Saved'
                        inputProps={{accept: '.json'}}
                        startAdornment={
                          <InputAdornment position="start">
                            <Publish/>
                          </InputAdornment>
                        }
                        save={(event, data) => handleInstanceCredentials(event, data)}
                        errorMessage={errors.connectionString}/>
      </Grid>
      {!isEmpty(props.connectivity) &&
      <Grid item md={8}>
        <TestConnectionResultTable connectivity={props.connectivity} connectionId={props.id}/>
      </Grid>
      }
    </Grid>
  </form>);
}

GoogleCloudConnectionEditForm.propTypes = {
  name: PropTypes.string,
  notes: PropTypes.string,
  basePrefix: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  errors: PropTypes.object,
  connectivity: PropTypes.object,

};
GoogleCloudConnectionEditForm.defaultProps = {};
export default GoogleCloudConnectionEditForm;