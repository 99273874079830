import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {FormControl, FormHelperText, Grid, Input, InputLabel, Link, Tooltip, Typography} from '@material-ui/core';
import {HelpOutline, OpenInNew} from '@material-ui/icons';
import {isEmpty} from '../../util/helpers';

export const StyledLink = styled(Link)`
    margin-left: auto;
`;

const StyledInputLabel = styled(InputLabel)`
  color: ${props => {
  let color;
  if (props.disabled) {
    color = '#0000008a';
  } else {
    color = '#000000cf';
  }
  return color;
}};
  letter-spacing: 0px;
`;

const tooltipLeaveDelay = 100;

const StyledLabelTooltip = styled(Tooltip)`
  margin-left: 5px;
  &:hover {
      cursor: pointer;
  }
`;

const StyledInput = styled(Input)`
  background-color: ${props => {
  let color;
  if (props.removebackground === 'true') {
    color = 'transparent';
  } else {
    color = 'rgba(0,0,0,0.02)';
  }
  return color;
}};
  color: #333;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  padding-left: 12px;
  border-radius: 3px;
  padding-right: 12px;
  input[type=file]::-webkit-file-upload-button {
    display: none;
  }
`;

const StyledCountHelper = styled(FormHelperText)`
  margin-left: auto;
`;

const StyledFormControl = styled(FormControl)`
  width: ${function setWidth(props) {
  return props.width
}}%;
  scroll-margin-top: 5rem;
`;

const StyledPrefixComponent = styled(Grid)`
  margin-top: ${props => (props.mt === 'true') ? '12px' : 0};
`;

const StyledPrefixComponentRightElement = styled(Grid)`
  flex-grow: 1;
`;

// noinspection FunctionNamingConventionJS, FunctionWithMoreThanThreeNegationsJS
function FormControlInput(props) {

  const fieldRef = useRef(null);

  React.useEffect(() => {
    if ((props.error || !isEmpty(props.errorMessage)) && fieldRef.current) {
      fieldRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }, [props.error, props.errorMessage]);

  const inputComponent = function renderStyledInputCompoent(props) {
    // noinspection ConditionalExpressionJS
    return <StyledInput type={props.type} fullWidth id={props.name} name={props.name} autoFocus={props.autoFocus}
                        {...(!props.uncontrolled &&
                            {value: isEmpty(props.value) ? '' : props.value}
                        )}
                        placeholder={props.placeholder}
                        required={props.required} disabled={props.disabled} onChange={props.onChange}
                        multiline={props.multiline} rows={props.rows} onBlur={props.onBlur}
                        autoComplete={props.autoComplete}
                        startAdornment={props?.startAdornment}
                        endAdornment={isEmpty(props.tooltip) ? props.endAdornment :
                            <>
                              {props.endAdornment}
                              <StyledLabelTooltip
                                  title={
                                    <Typography variant={"body2"}>{props.tooltip} </Typography>}
                                  leaveDelay={tooltipLeaveDelay}>
                                <HelpOutline fontSize='small'/>
                              </StyledLabelTooltip>
                            </>
                        }
                        inputProps={props.inputProps} onKeyDown={props.onKeyDown}
                        removebackground={props.removeBackground.toString()}
    />
  };

  return (
      <StyledFormControl error={props.error || !isEmpty(props.errorMessage)}
                         ref={fieldRef} width={props.width} margin={props.margin}>
        {!isEmpty(props.label) &&
        <StyledInputLabel disabled={props.disabled} shrink required={props.required} filled htmlFor={props.name}>
          {props.label}
        </StyledInputLabel>
        }
        {!isEmpty(props.helpLink) &&
        <StyledLink
            href={props.helpLink?.href}
            target={props.helpLink?.target}
            rel='noopener'
        >
          {props.helpLink?.target === '_blank' &&
          <OpenInNew fontSize='small' style={{verticalAlign: 'sub', marginRight: '4px'}}/>}
          {props.helpLink?.text}
        </StyledLink>
        }
        {!isEmpty(props.prefix) &&
        <StyledPrefixComponent mt={(!isEmpty(props.label)).toString()} container spacing={1} alignItems='center'>
          <Grid item>{props.prefix}</Grid>
          <StyledPrefixComponentRightElement item style={{flexGrow: 1}}>
            {inputComponent(props)}
          </StyledPrefixComponentRightElement>
        </StyledPrefixComponent>
        }

        {isEmpty(props.prefix) && inputComponent(props)}

        {(!isEmpty(props.helperText) && isEmpty(props.errorMessage)) &&
        <FormHelperText id={`${props.name}-helper-text`}>
          {props.helperText}
        </FormHelperText>
        }
        {props.showCount &&
        <StyledCountHelper id={`${props.name}-count`}>
          {props.value?.length || 0}/{props.inputProps.maxLength}
        </StyledCountHelper>
        }
        {!isEmpty(props.errorMessage) &&
        <FormHelperText id={`${props.name}-error-text`}>
          {props.errorMessage}
        </FormHelperText>
        }
      </StyledFormControl>
  )
}

FormControlInput.propTypes = {
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    helpLink: PropTypes.shape({
        text: PropTypes.string,
        href: PropTypes.string,
        target: PropTypes.string,
    }),
    width: PropTypes.string,
    name: PropTypes.string,
    helperText: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    onChange: PropTypes.func,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    tooltip: PropTypes.string,
    showCount: PropTypes.bool,
    prefix: PropTypes.string,
    rows: PropTypes.number,
    multiline: PropTypes.bool,
    margin: PropTypes.string,
    inputProps: PropTypes.object,
    value: PropTypes.string,
    autoFocus: PropTypes.bool,
    onBlur: PropTypes.func,
    onKeyDown: PropTypes.func,
    removeBackground: PropTypes.bool,
    autoComplete: PropTypes.string,
    uncontrolled: PropTypes.bool,
    endAdornment: PropTypes.object,
};

FormControlInput.defaultProps = {
  type: 'text',
  error: false,
  showCount: false,
  margin: 'normal',
  removeBackground: false,
  autoComplete: 'on',
};

export default FormControlInput;
