import React, {useEffect, useState} from 'react';
import {Button, Grid, IconButton, InputAdornment, useTheme} from "@material-ui/core";
import toast from "toasted-notes";
import CustomAlert from "../common/CustomAlert";
import FormControlInput from "../common/FormControlInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy as copyIcon} from "@fortawesome/free-regular-svg-icons";
import PageSection from "../common/PageSection";
import {isEmpty} from "../../util/helpers";
import TestConnectionResultTable from "./TestConnectionResultTable";
import CircularProgressButton from "../common/CircularProgressButton";
import styled from "styled-components";
import PropTypes from "prop-types";
import {Publish} from "@material-ui/icons";

const StyledButton = styled(Button)`
  margin-top: ${props => props.theme.spacing(2)}px;
`;

// noinspection FunctionNamingConventionJS
function GoogleCloudConnectionForm(props) {

  const theme = useTheme();
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  useEffect(() => {
    setLoadingSubmit(false);
  }, [props.errors]);

  const handleUploadKey = (event) => {
    let inputFile = event?.target.files[0];
    let reader = new FileReader();
    reader.onload = function readOnLoad(e) {
      let contents = e.target.result;
      props.setJwtToken({[event.target.name]: Buffer.from(contents).toString('base64'), jsonKeyFilename: inputFile.name});
    };
    reader.readAsBinaryString(inputFile);
  };

  const handleSubmit = async function handleFolderSubmit(event) {
    setLoadingSubmit(true);
    await props.handleSubmit(event);
  };

  const copyUrl = (url) => {
    navigator.clipboard.writeText(url).then(() => {
      toast.notify(({onClose}) => <CustomAlert type='success' message='Url copied!' onClose={onClose}/>);
    });
  };

  return (<form onSubmit={handleSubmit} noValidate>

    <Grid container justifyContent='center'>
      <Grid container item md={8}>
        <FormControlInput value={props.name} width='100' label='Connection Name' name='name'
                          placeholder='Enter unique name' onChange={props.setName} required
                          helperText='Name used to identify this cloud connection.'
                          errorMessage={props.errors.name}
        />
      </Grid>
      <Grid container item md={8}>
        <FormControlInput value={props.notes} width='100' label='Cloud Connection Notes'
                          name='notes' multiline onChange={props.setNotes}
                          inputProps={{maxLength: 1000}} showCount
                          placeholder='Enter notes about the cloud connection (optional)'
                          errorMessage={props.errors.notes}
        />
      </Grid>
      <Grid container item md={8}>
        <FormControlInput value={props.basePrefix} width='100' label='GCS Bucket URI' name='basePrefix'
                          required
                          autoComplete="off"
                          onChange={props.setBasePrefix} placeholder='Enter GsUtil URI for GCP Bucket'
                          helperText='Ex: gs://bucket-name'
                          errorMessage={props.errors.basePrefix}
                          endAdornment={<InputAdornment position='end'>
                            <IconButton onClick={function handleCopyUrl(event) {
                              event.stopPropagation()
                              copyUrl(props.basePrefix)
                            }}>
                              <FontAwesomeIcon icon={copyIcon} color={theme.palette.primary.main}
                                               size={"xs"}/>
                            </IconButton>
                          </InputAdornment>}
        />
      </Grid>
      <Grid container item md={8}>

        <PageSection title='Cloud Connection Credentials' mt={4} pb={0} titleVariant='body1'
                     subtitle='Credentials used to access this cloud connection.' subtitleVariant='body2'/>

      </Grid>
      <Grid container item md={8}>
        <FormControlInput width='100' label='Upload Service Account JSON Key File'
                          uncontrolled
                          helpLink={{
                            href: 'http://www.sftpgateway.com/google-service-account-key-file.html',
                            target: '_blank',
                            text: 'Where do I find the Service Account JSON Key File?'
                          }}
                          name='jwtToken' onChange={handleUploadKey}
                          errorMessage={props.errors.jwtToken}
                          required
                          type='file'
                          inputProps={{accept: '.json'}}
                          startAdornment={
                            <InputAdornment position="start">
                              <Publish/>
                            </InputAdornment>
                          }
        />
      </Grid>
      {!isEmpty(props.connectivity) &&
      <TestConnectionResultTable connectivity={props.connectivity} connectionId={props.id}/>
      }
      <Grid container item justifyContent='flex-end' md={8}>
        <CircularProgressButton theme={theme} type='submit' size='small' mr={1}
                                label='Save' mt={2} inProgress={loadingSubmit}/>
        <StyledButton onClick={props.handleCancel} disableElevation size='small' type='reset'
                      theme={theme}>Cancel</StyledButton>
      </Grid>
    </Grid>
  </form>);
}

GoogleCloudConnectionForm.propTypes = {
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  name: PropTypes.string,
  setName: PropTypes.func,
  notes: PropTypes.string,
  setNotes: PropTypes.func,
  basePrefix: PropTypes.string,
  setBasePrefix: PropTypes.func,
  useInstanceCredentials: PropTypes.bool,
  setInstanceCredentials: PropTypes.func,
  jwtToken: PropTypes.string,
  setJwtToken: PropTypes.func,
  errors: PropTypes.object,
  showInstanceCredentialsOption: PropTypes.bool,
};
GoogleCloudConnectionForm.defaultProps = {};
export default GoogleCloudConnectionForm;