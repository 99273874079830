import React, {useState} from 'react';
import axios from 'axios';
import toast from 'toasted-notes';
import styled from 'styled-components';
import {Link as RouterLink, NavLink, useHistory} from 'react-router-dom';
import {Icon} from '@iconify/react';
import awsIcon from '@iconify-icons/mdi/aws';
import azureIcon from '@iconify-icons/mdi/microsoft-azure';
import googleIcon from '@iconify-icons/mdi/google-cloud';
import {
    Button,
    Grid,
    IconButton,
    ListItemIcon,
    makeStyles,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableContainer,
    TableRow
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {useTheme} from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCopy as copyIcon} from '@fortawesome/free-regular-svg-icons';
import PageLoader from '../common/PageLoader';
import PageSection from '../common/PageSection';
import CustomAlert from '../common/CustomAlert';
import ConfirmDialog from '../common/ConfirmDialog';
import CustomDeleteIcon from '../common/CustomDeleteIcon';
import SuccessMessageComponent from '../common/SuccessMessageComponent';
import {
    StyledEditIconButton,
    StyledTableCell,
    StyledTableHead,
    StyledTableHeadCell,
    StyledTableRow
} from '../common/styled';
import routes from '../../util/routes';
import api_routes from '../../util/api_routes';
import httpStatus from '../../util/http_status';
import {isEmpty, truncateString} from '../../util/helpers';
import useCloudConnections from '../../api/useCloudConnections';
import Feature from '../common/Feature';
import {AWS, AZURE, GCP} from '../../util/cloud_providers';

const StyledConnectionButton = styled(Button)`
  text-transform: none;
`;

const StyledRouterLink = styled(NavLink)`
    text-decoration: none;
    color: inherit;
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 0.85rem;
`;

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 32px;
`;

const StyledTableIcon = styled(ListItemIcon)`
  margin-left: 0.5rem;
  min-width: 32px;
  vertical-align: middle;
`;

// noinspection AnonymousFunctionJS
const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    logButton: {
        textTransform: 'none',
    },
    subheader: {
        marginTop: '16px',
    }
}));

// noinspection FunctionNamingConventionJS
function SettingsCompCloudConnectionsList() {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState(null);
    const [triggerRefresh, setRefresh] = useState(1);
    const {
        isLoading: loadingCloudConnections,
        cloudConnections
    } = useCloudConnections({dependencies: {triggerRefresh}});
    const [showConfirmDeleteId, setShowConfirmDeleteId] = useState(null);
    const [showSavedMessage, setShowSavedMessage] = useState(false);

    const deleteCloudConnection = async function deleteCloudConnection(cloudConnection) {
        try {
            await axios.delete(`${api_routes.cloudConnection.endpoint}/${cloudConnection.id}`);
            setRefresh(triggerRefresh => triggerRefresh + 1);
            setShowSavedMessage(true);
        } catch (error) {
            if (!isEmpty(error.response) && error.response.status === httpStatus.conflict) {
                toast.notify(({onClose}) =>
                    <CustomAlert message={`Could not delete cloud connection with folders.`}
                                 onClose={onClose} type='error'/>);
            }
        }
    };

    const providerIcon = (connection) => {
        switch (connection.cloudProvider) {
            case "aws":
                return awsIcon;
            case "azure":
                return azureIcon;
            case "gcp":
                return googleIcon;
            default:
                return null;
        }
    }

    const displayAccessKeyId = function displayAccessKeyIdInTheCloudConnectionsTable(row) {
        let accessKeyId = '';
        if (!row.useInstanceCredentials) {
            // noinspection MagicNumberJS
            switch (row.cloudProvider) {
                case AWS:
                    accessKeyId = truncateString(row.accessKeyId, 25);
                    break;
                case AZURE:
                    accessKeyId = truncateString(row.accountName, 25);
                    break;
                case GCP:
                    //TODO: Update with new token flag
                    if (!isEmpty(row.jwtToken)) {
                        let encodedJwtToken = row.jwtToken;
                        accessKeyId = ''
                    }
                    break;
                default:
                    console.error("Unsupported cloud provider: " + row.cloudProvider)
            }
        }
        return accessKeyId;
    };

    const copyUrl = (url) => {
        navigator.clipboard.writeText(url).then(() => {
            toast.notify(({onClose}) => <CustomAlert type='success' message='Url copied!' onClose={onClose}/>);
        });
    };

    // noinspection MagicNumberJS
    return (<>
            <PageSection title='Cloud Connections'
                         subtitle='The following connections to the cloud can be used when creating folders.'
                         action={<Grid container alignItems='center'>
                             <SuccessMessageComponent show={showSavedMessage} setShow={setShowSavedMessage} mr={2}/>
                             <StyledConnectionButton theme={theme} size='small' color='primary' variant='contained'
                                                     disableElevation
                                                     aria-controls='user-menu'
                                                     aria-haspopup='true'
                                                     onClick={(event) => setAnchorEl(event.currentTarget)}
                                                     endIcon={<ArrowDropDownIcon/>}>
                                 Add New Connection
                             </StyledConnectionButton>
                             <Menu id='user-menu'
                                   keepMounted
                                   anchorEl={anchorEl}
                                   open={Boolean(anchorEl)}
                                   onClose={() => setAnchorEl(null)}
                                   getContentAnchorEl={null}
                                   anchorOrigin={{
                                       vertical: 'bottom',
                                       horizontal: 'right'
                                   }}
                                   transformOrigin={{
                                       vertical: 'top',
                                       horizontal: 'right'
                                   }}
                             >
                                 <StyledRouterLink theme={theme}
                                                   to={`${routes.settings.path}${routes.cloudConnections.path}${routes.createCloudConnection.path}?provider=aws`}>
                                     <StyledMenuItem onClick={() => setAnchorEl(null)}>
                                         <StyledListItemIcon><Icon width={22} icon={awsIcon}/></StyledListItemIcon>
                                         Amazon Web Services
                                     </StyledMenuItem>
                                 </StyledRouterLink>
                                 <Feature name='azure'>
                                     <StyledRouterLink theme={theme}
                                                       to={`${routes.settings.path}${routes.cloudConnections.path}${routes.createCloudConnection.path}?provider=azure`}>
                                         <StyledMenuItem onClick={() => setAnchorEl(null)}>
                                             <StyledListItemIcon><Icon width={22}
                                                                       icon={azureIcon}/></StyledListItemIcon>
                                             Azure Cloud Services
                                         </StyledMenuItem>
                                     </StyledRouterLink>
                                 </Feature>
                                 <Feature name='gcp'>
                                     <StyledRouterLink theme={theme}
                                                       to={`${routes.settings.path}${routes.cloudConnections.path}${routes.createCloudConnection.path}?provider=gcp`}>
                                         <StyledMenuItem onClick={() => setAnchorEl(null)}>
                                             <StyledListItemIcon><Icon width={22}
                                                                       icon={googleIcon}/></StyledListItemIcon>
                                             Google Cloud Services
                                         </StyledMenuItem>
                                     </StyledRouterLink>
                                 </Feature>
                             </Menu>
                         </Grid>}
            />
            <TableContainer>
                <Table className={classes.table} aria-label='cloud-connections-table'>
                    <StyledTableHead theme={theme}>
                        <TableRow>
                            <StyledTableHeadCell theme={theme} align='left'>Connection
                                Name</StyledTableHeadCell>
                            <StyledTableHeadCell theme={theme}
                                                 align='left'>Bucket/Container</StyledTableHeadCell>
                            <StyledTableHeadCell theme={theme} align='left'>Access Key/Account
                                Name</StyledTableHeadCell>
                            <StyledTableHeadCell theme={theme} align='center'>Actions</StyledTableHeadCell>
                        </TableRow>
                    </StyledTableHead>
                    <TableBody>
                        {loadingCloudConnections &&
                        <StyledTableRow theme={theme}>
                            <StyledTableCell colSpan={4}><PageLoader/></StyledTableCell>
                        </StyledTableRow>
                        }
                        {!loadingCloudConnections && cloudConnections.length === 0 &&
                        <StyledTableRow theme={theme}>
                            <StyledTableCell align='center' colSpan={4}>No records to display</StyledTableCell>
                        </StyledTableRow>
                        }
                        {!loadingCloudConnections && cloudConnections.map((connection, idx) => (
                            <StyledTableRow theme={theme} key={connection.id || idx}>
                                <StyledTableCell
                                    onClick={() => history.push(`${routes.settings.path}${routes.cloudConnections.path}/${connection.id}`)}>
                                    {connection.name}
                                    <StyledTableIcon>
                                        <Icon width={22} icon={providerIcon(connection)}/>
                                    </StyledTableIcon>
                                </StyledTableCell>
                                <StyledTableCell
                                    onClick={() => history.push(`${routes.settings.path}${routes.cloudConnections.path}/${connection.id}`)}
                                    align='left'>
                                    {connection.basePrefix}
                                    <IconButton size={"small"}
                                                style={{height: '25px', width: '25px', marginBottom: '4px'}}
                                                onClick={function handleCopyUrl(event) {
                                                    event.stopPropagation()
                                                    copyUrl(connection.basePrefix)
                                                }}>
                                        <FontAwesomeIcon icon={copyIcon} color={theme.palette.primary.main}
                                                         size={"xs"}/>
                                    </IconButton>
                                </StyledTableCell>
                                <StyledTableCell
                                    onClick={() => history.push(`${routes.settings.path}${routes.cloudConnections.path}/${connection.id}`)}
                                    align='left'>
                                    {displayAccessKeyId(connection)}
                                </StyledTableCell>
                                <StyledTableCell align='center'>
                                    <RouterLink
                                        to={`${routes.settings.path}${routes.cloudConnections.path}/${connection.id}`}>
                                        <StyledEditIconButton size='small'>
                                            <EditIcon fontSize='small' color='primary'/>
                                        </StyledEditIconButton>
                                    </RouterLink>
                                    <IconButton size='small' onClick={() => setShowConfirmDeleteId(connection.id)}>
                                        <CustomDeleteIcon fontSize='small'/>
                                    </IconButton>
                                    <ConfirmDialog
                                        title='Delete Cloud Connection'
                                        open={showConfirmDeleteId === connection.id}
                                        setOpen={setShowConfirmDeleteId}
                                        onConfirm={() => deleteCloudConnection(connection)}
                                    >
                                        Are you sure you want to delete {connection.name}?
                                    </ConfirmDialog>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

SettingsCompCloudConnectionsList.propTypes = {};

SettingsCompCloudConnectionsList.defaultProps = {};

export default SettingsCompCloudConnectionsList;
