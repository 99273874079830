import React, {useEffect, useState} from 'react';
import toast from 'toasted-notes';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCopy as copyIcon} from '@fortawesome/free-regular-svg-icons';
import {Button, Grid, IconButton, InputAdornment, Typography, useTheme} from '@material-ui/core';
import PageSection from '../common/PageSection';
import CustomAlert from '../common/CustomAlert';
import FormControlInput from '../common/FormControlInput';
import FormControlRadioGroup from '../common/FormControlRadioGroup';
import CircularProgressButton from '../common/CircularProgressButton';
import TestConnectionResultTable from './TestConnectionResultTable';
import {isEmpty} from '../../util/helpers';

const StyledButton = styled(Button)`
  margin-top: ${props => props.theme.spacing(2)}px;
`;

// noinspection FunctionNamingConventionJS
function AwsCloudConnectionForm(props) {

    const theme = useTheme();
    const [loadingSubmit, setLoadingSubmit] = useState(false);

    useEffect(() => {
        setLoadingSubmit(false);
    }, [props.errors]);

    const handleSubmit = async function handleFolderSubmit(event) {
        setLoadingSubmit(true);
        await props.handleSubmit(event);
    };

    const copyUrl = (url) => {
        navigator.clipboard.writeText(url).then(() => {
            toast.notify(({onClose}) => <CustomAlert type='success' message='Url copied!' onClose={onClose}/>);
        });
    };
    //noinspection MagicNumberJS, ConditionalExpressionJS
    return (<form onSubmit={handleSubmit} noValidate>
        <Grid container justifyContent='center'>
            <Grid container item md={8}>
                <FormControlInput value={props.name} width='100' label='Connection Name' name='name'
                                  placeholder='Enter unique name' onChange={props.setName} required
                                  helperText='Name used to identify this cloud connection.'
                                  errorMessage={props.errors.name}
                />
            </Grid>
            <Grid container item md={8}>
                <FormControlInput value={props.notes} width='100' label='Cloud Connection Notes'
                                  name='notes' multiline onChange={props.setNotes}
                                  inputProps={{maxLength: 1000}} showCount
                                  placeholder='Enter notes about the cloud connection (optional)'
                                  errorMessage={props.errors.notes}
                />
            </Grid>
            <Grid container item md={8}>
                <FormControlInput value={props.basePrefix} width='100' label='S3 URL' name='basePrefix' required
                                  onChange={props.setBasePrefix} placeholder='Enter URL for S3 bucket'
                                  helperText="Ex: s3://bucket-name/key-prefix"
                                  errorMessage={props.errors.basePrefix} autoComplete='off'
                                  endAdornment={<InputAdornment position='end'>
                                      <IconButton onClick={function handleCopyUrl(event) {
                                          event.stopPropagation()
                                          copyUrl(props.basePrefix)
                                      }}>
                                          <FontAwesomeIcon icon={copyIcon} color={theme.palette.primary.main}
                                                           size={"xs"}/>
                                      </IconButton>
                                  </InputAdornment>}
                />
            </Grid>
            <Grid container item md={8}>
                <FormControlInput value={props.region} width='100' label='Region' name='region'
                                  onChange={props.setRegion} autoComplete='off'
                                  placeholder='Enter a bucket region or leave blank for default'
                                  helperText="Ex: us-east-1"
                                  errorMessage={props.errors.region}
                />
            </Grid>
            <Grid container item md={8}>
                <Grid item xs={12}>
                    <PageSection title='S3 Encryption Type' titleVariant='body1'
                                 subtitle='Uploaded objects will be encrypted to this level.'
                                 subtitleVariant='body2'
                                 pb={0} mt={3}/>
                </Grid>
                <Grid item xs={12}>
                    <FormControlRadioGroup name='encryptionType' value={props.encryptionType}
                                           onChange={props.setEncryptionType}
                                           helperText={'New objects uploaded by the users will be encrypted to this level. ' +
                                           'Default and recommended option is SSE-S3.'}
                                           options={[{
                                               value: 'SSE_S3', label: 'SSE-S3'
                                           }, {
                                               value: 'NONE', label: 'No Encryption'
                                           }, {
                                               value: 'SSE_KMS',
                                               label: <Grid container direction='column' style={{marginTop: '9px'}}>
                                                   <Grid item><Typography variant='body2'>KMS Key</Typography></Grid>
                                                   {props.encryptionType === 'SSE_KMS' && <>
                                                       <Grid item>
                                                           <FormControlInput value={props.encryptionId} width='100'
                                                                             name='encryptionId'
                                                                             placeholder='Enter KMS Key'
                                                                             autoComplete='off'
                                                                             onChange={props.setEncryptionId}
                                                           />
                                                       </Grid>
                                                   </>
                                                   }
                                               </Grid>,
                                               style: {alignItems: 'flex-start'}
                                           }]}
                    />
                </Grid>
            </Grid>
            <Grid container item md={8}>
                <PageSection title='Cloud Connection Credentials' mt={4} pb={0} titleVariant='body1'
                             subtitle='Credentials used to access this cloud connection.' subtitleVariant='body2'/>
                <FormControlRadioGroup name='useInstanceCredentials' value={props.useInstanceCredentials}
                                       onChange={props.setInstanceCredentials}
                                       options={[
                                           ...(props.showInstanceCredentialsOption ?
                                               [{value: true, label: 'Use instance profile credentials'}] :
                                               []), {
                                               value: false, label: 'Use unique credentials'
                                           }]}
                />
            </Grid>
            <Grid container item md={8}>
                <div style={{width: '100%', paddingLeft: '28px', marginTop: '-16px'}}>
                    <FormControlInput value={props.keyId} width='100' label='AWS Access Key ID' name='accessKeyId'
                                      onChange={props.setKeyId} placeholder='Enter Key ID'
                                      helperText='This AWS ID is used for this cloud connection.'
                                      disabled={props.useInstanceCredentials} required={!props.useInstanceCredentials}
                                      errorMessage={props.errors.accessKeyId} autoComplete='off'
                    />
                    <FormControlInput value={props.keySecret} width='100' label='AWS Access Secret'
                                      name='accessSecret' onChange={props.setKeySecret} autoComplete='off'
                                      placeholder={`${props.accessSecretSet ? '**********' : 'Enter secret for key ID'}`}
                                      helperText='This AWS access secret is used for this cloud connection.'
                                      type='password' errorMessage={props.errors.accessSecret}
                                      disabled={props.useInstanceCredentials} required={!props.useInstanceCredentials}
                    />
                </div>
            </Grid>
            {!isEmpty(props.connectivity) &&
            <TestConnectionResultTable connectivity={props.connectivity} connectionId={props.id}/>
            }
            <Grid container item justifyContent='flex-end' md={8}>
                <CircularProgressButton theme={theme} type='submit' size='small' mr={1}
                                        label='Save' mt={2} inProgress={loadingSubmit}/>
                <StyledButton onClick={props.handleCancel} disableElevation size='small' type='reset'
                              theme={theme}>Cancel</StyledButton>
            </Grid>
        </Grid>
    </form>);
}

AwsCloudConnectionForm.propTypes = {
    name: PropTypes.string,
    setName: PropTypes.func,
    notes: PropTypes.string,
    setNotes: PropTypes.func,
    basePrefix: PropTypes.string,
    setBasePrefix: PropTypes.func,
    keyId: PropTypes.string,
    setKeyId: PropTypes.func,
    keySecret: PropTypes.string,
    setKeySecret: PropTypes.func,
    region: PropTypes.string,
    setRegion: PropTypes.func,
    useInstanceCredentials: PropTypes.bool,
    setInstanceCredentials: PropTypes.func,
    encryptionType: PropTypes.string,
    setEncryptionType: PropTypes.func,
    encryptionId: PropTypes.string,
    setEncryptionId: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleCancel: PropTypes.func,
    errors: PropTypes.object,
    accessSecretSet: PropTypes.bool,
    connectivity: PropTypes.object,
    showInstanceCredentialsOption: PropTypes.bool
};

AwsCloudConnectionForm.defaultProps = {
    accessSecretSet: false,
};

export default AwsCloudConnectionForm;
