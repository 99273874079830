import React, {useCallback, useEffect, useState} from 'react';
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';

import Login from '../../component/auth/Login';
import SignUp from '../../component/auth/SignUp';
import PageLoader from '../../component/common/PageLoader';
import UpdatePassword from '../../component/auth/UpdatePassword';
import ServerStartingUp from '../../component/auth/ServerStartingUp';
import {setAuthenticated, setCurrentUser, setSystemInformation} from '../../action';
import {authenticationService} from './authenticationService';
import api_routes from '../../util/api_routes';
import httpStatus from '../../util/http_status';
import {isEmpty} from '../../util/helpers';

// noinspection FunctionNamingConventionJS
function Authenticator(props) {

    const dispatch = useDispatch();
    const currentUser = useSelector(function getCurrentUser(state) {
        return state.currentUser;
    })
    const authenticated = useSelector(function getAuthenticatedStatus(state) {
        return state.auth.authenticated
    });
    const [displayComponent, setDisplayComponent] = useState(<PageLoader/>);

    const dispatchLocalAuthInfo = useCallback(async function dispatchLocalAuthInfo(name) {
        const response = await axios.get(api_routes.ping.endpoint);
        // noinspection NestedFunctionCallJS
        dispatch(setSystemInformation(response.data));
        // noinspection NestedFunctionCallJS
        dispatch(setAuthenticated(true));
        // noinspection NestedFunctionCallJS
        dispatch(setCurrentUser({username: name}));
    }, [dispatch]);

    const successfulPasswordUpdate = useCallback(async function successfullyChangedOTP(username, password) {
        await authenticationService.login(username, password);
        await dispatchLocalAuthInfo(username);
        setDisplayComponent(props.children);
    }, [dispatchLocalAuthInfo, props.children]);

    const login = useCallback(async function loginToWeb(username, password) {
        const {username: name, resetPassword} = await authenticationService.login(username, password);
        if (resetPassword) {
            setDisplayComponent(<UpdatePassword username={name} currentPassword={password}
                                                onSuccess={successfulPasswordUpdate} />);
        } else {
            await dispatchLocalAuthInfo(name);
            setDisplayComponent(props.children);
        }
    }, [props.children, dispatchLocalAuthInfo, successfulPasswordUpdate]);

    const successfulSignUp = useCallback(function successfullySingedUpTheFirstUser() {
        setDisplayComponent(<Login login={login}/>);
    }, [login]);

    useEffect(() => {
        axios.defaults.headers.common['X-Username'] = `${currentUser.username}`;
    }, [currentUser]);

    useEffect(() => {
        const authenticate = async function performAuthenticationSteps() {
            // Check if the system hasn't been signed into before:
            let firstAccess = true;
            let backendRunning = true;
            try {
                let response = await axios.get(api_routes.firstUserConfiguration.endpoint);
                // No user is configured, use first sign in flow
                if (response && response.status === httpStatus.noContent) {
                    setDisplayComponent(<SignUp onSuccess={successfulSignUp}/>);
                }
            } catch (error) {
                if (!isEmpty(error.message) && error.message.includes(httpStatus.notFound.toString())) {
                    firstAccess = false;
                } else {
                    backendRunning = false;
                }
            }

            if (!backendRunning) {
                setDisplayComponent(<ServerStartingUp />);
            }

            if (!firstAccess) {
                // noinspection NestedFunctionCallJS
                if (isEmpty(authenticationService.token)) {
                    setDisplayComponent(<Login login={login}/>);
                } else {
                    axios.defaults.headers.common['Authorization'] = `Bearer ${authenticationService.token}`;
                    try {
                        await dispatchLocalAuthInfo(authenticationService.username);
                        setDisplayComponent(props.children);
                    } catch {
                        setDisplayComponent(<Login login={login}/>);
                    }
                }
            }
        };

        // noinspection JSIgnoredPromiseFromCall
        authenticate();
    }, [dispatch, props.children, login, successfulSignUp, dispatchLocalAuthInfo]);

    useEffect(() => {
        if (!isEmpty(authenticated) && !authenticated) {
            setDisplayComponent(<Login login={login}/>);
        }
    }, [authenticated, login]);

    return displayComponent;
}

export default Authenticator;
