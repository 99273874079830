import React from 'react';
import {Grid} from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import cloud_providers from '../../util/cloud_providers';
import styled from 'styled-components';

const {cloudProvider, version} = window._env_ || {};
const CLOUD_PROVIDER = cloudProvider || process.env.REACT_APP_CLOUD_PROVIDER;
const VERSION = version || process.env.REACT_APP_VERSION;

const StyledLogo = styled.img`
  width: 200px;
`;

// noinspection FunctionNamingConventionJS
function ServerStartingUp() {

    const getDocumentationLink = () => {
        return cloud_providers.filter(provider => provider.name === CLOUD_PROVIDER)[0].documentationUrl;
    }

    return (
        <Grid container direction='column' justifyContent='center' alignItems='center' className='setting-up-screen'>
            <Grid item container justifyContent='center' alignItems='center'
                  direction='column' style={{marginBottom: '32px'}}>
                <StyledLogo src='/images/sftpgateway-logo-final.png' alt='SFTPgateway logo' />
                <StyledLogo src='/images/sftpgateway-logo-final-text.png' alt='SFTPgateway logo text'/>
            </Grid>
            <Grid item>
                <Typography>
                    Please wait while SFTP Gateway finishes setting up and try again in a minute...
                </Typography>
            </Grid>
            <Grid item>
                <Typography>
                    Meanwhile, take a moment to view
                    our <Link href={getDocumentationLink()} target='_blank'>documentation</Link> for
                    setting up SFTP Gateway version {VERSION}.
                </Typography>
            </Grid>
        </Grid>
    );
}

ServerStartingUp.propTypes = {};

ServerStartingUp.defaultProps = {};

export default ServerStartingUp;
